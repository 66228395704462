@import "./variables";
@import "~bootstrap/less/mixins/buttons.less";
@import "~bootstrap/less/mixins/labels.less";

.unbox-shadow() {
  -webkit-box-shadow: none; // iOS <4.3 & Android <4.1
  box-shadow: none;
}

.btn(@color-name) {
  background-color: ~"@{button-border-@{color-name}}";
  border-color: ~"@{button-border-@{color-name}}";
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  &.inverted {
    font-weight: bold;
    border-color: ~"@{button-border-inverted-@{color-name}}";
    background: none;
    color: ~"@{button-bg-inverted-@{color-name}}";
  }
}

.font(@color-name) {
  color: ~"@{dev-portal-card-@{color-name}-button-bg}";
}

.icon-creator(@path; @path_hover) {
  background: url(@path) no-repeat 12px;
  background-size: 20px 20px;
  &:hover {
    background-image: url(@path_hover);
  }
}

@media (min-width: @screen-sm-min) {
  .pull-right-sm {
    float: right !important;
  }
}

@media (min-width: @screen-md-min) {
  .pull-right-md {
    float: right !important;
  }
}

@media (min-width: @screen-lg-min) {
  .pull-right-lg {
    float: right !important
  }
}

