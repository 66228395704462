
.error_page-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.error_page-bold_text {
  font-weight: bold;
}

.error_page-text {
  color: black;
}

.error_page-text_block {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.error_page-btn {
  margin-top: 30px;
  align-content: center;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}