@import "./variables";
@import "./vendor/bootstarp-light";
@import "mixins";
@import "auth2_error.less";

html {
  position: relative;
  min-height: 100%;
}

h1, h2, h3, h4 {
  font-weight: 300;
}

.auth-button-box {
  width: 100%;
  padding: 5px;
  margin-bottom: 10px;
  border: solid 2px #3ba2ff;
  border-radius: 8px;
  color: #3ba2ff;
  line-height: 1.75;
  font-size: 16px;
  font-weight: bold;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  transition: all 200ms ease-out;
  transition-property: all;
  transition-duration: 200ms;
  transition-timing-function: ease-out;
  transition-delay: initial;
      height: 40px;
}

.auth-button-box:hover {
  color: gray;
  border-color: #4596e8;
  box-shadow: 0 0 6px #4596e8;
}


.textOr {
  margin-top: 16px;
  margin-bottom: 22px;
  text-align: center;
  color: #bcbcbc;
}

.o-main {
  padding-bottom: 55px;
}

.center-form {
  max-width: 364px;
  margin: auto;
  padding-top: 63px;
  width: 80%;
  padding-bottom: 20px;
}

.h-title {
  margin-top: 0;
  color: #555;
  font-size: 24px;
}

.list-inline {
  margin: auto;
  padding: 0;
  li {
    display: inline-block;
    padding: 0 3px;
  }
}

.form-group {
  margin-bottom: 4px;
  margin-top: 20px;
}

input.form-control {
  border-radius: 4px;
  height: 42px;
  font-size: 16px;
  border: solid 1px #7c879f;
  padding-left: 20px;
  &:focus {
    border: solid 2px #66afe9;
  }
}
.hidePass {
  display: none;
}
.hidePass.visible{
  display: block !important;
  margin-top: 3px;
}
.showPass {
  display: none;
}
.showPass.visible{
  display: block !important;
  margin-top: 3px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.passwordMinSize {
  font-size: 10px;
  font-weight: 600;
  line-height: 1.5;
  color: #8792a9;
  margin-left: 3px;
  margin-bottom: 14px;
}

.captcha {
  margin-bottom: 20px;
  .g-recaptcha {
    transform: scale(1.20);
    div:first-child {
      margin: 25px auto auto;
    }
  }
}

.warning {
  display: none;
  &.active {
    display: block !important;
    margin-left: 4px;
    font-size: 11px;
  }
}
#error-div {
  padding-left: 4px;
}

.btn {
  min-height: 44px;
  font-size: 16px;
  height: 40px;
  width: 100%;
}
.loginButton {
  background-color: #44d7b6;
  border-radius: 10px;
  color: #ffffff;
  margin-bottom: 5px;
  margin-top: 5px;
  outline: none !important;
   -webkit-box-shadow: 3px 3px 5px 6px #ccc;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow:    3px 3px 5px 6px #ccc;  /* Firefox 3.5 - 3.6 */
  box-shadow: 0 5px 15px 3px rgba(68, 215, 182, 0.1), 0 5px 10px 1px rgba(68, 215, 182, 0.2);
  &:hover {
    background-color: #44bfa3;
    border: 3px solid #43D7B6;
  }
}
.idRemember {
  margin-top: 3px;
  color: #bcbcbc;
  font-size: 16px;
}
#id_remember {
  margin-top: 4px;
}
.small {
  font-size: 16px;
  margin-left: 4px;
  color: #3ba2ff;
  &:hover {
   text-decoration: inherit;
  }
}
#rememberText {
  color: #bcbcbc;
}
#id_password {
  width: 100%;
}
#hidePassword {
  position: relative;
}
#button {
  position:absolute;
  right: 10px;
  top: 8px;
  border:none;
  height:20px;
  width:20px;
  background-color: white;
  outline:none;
  text-align:center;
  font-weight:bold;
  padding:2px;
}

.logo {
  margin-bottom: 32px;
}
.newToPipl {
  font-size: 16px;
  line-height: 2.13;
  text-align: center;
  color: #9e9e9e;
  margin-right: auto;
  width: 115px;
  background-color: white;
  z-index: 100;
  margin-left: auto;
}
.singleSignOn {
  font-size: 16px;
  line-height: 2.13;
  text-align: center;
  color: #9e9e9e;
  margin-right: auto;
  width: 270px;
  background-color: white;
  z-index: 100;
  margin-left: auto;
}
.newToPiplBar {
  margin-top: 10px;
  margin-bottom: 22px;
}
.titleBar {
  position:absolute;
  width: 365px;
  background: #bcbcbc;
  height: 1px;
  margin-top: 15px;
  z-index: -1;

  @media screen and (max-width: @screen-sm-max){
    width: 80%;
  }
}
.alreadyHaveAccountHeader {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}
.signinLink {
  font-weight: bold;
  color: #3ba2ff;
  font-size: 16px;
  &:hover {
    text-decoration: underline;
    color: #3ba2ff;
  }
}

.authOptionsLink {
  font-weight: bold;
  color: #3ba2ff;
  font-size: 16px;
  margin-top: 10px;
  &:hover {
    text-decoration: underline;
    color: #3ba2ff;
  }
}

.centered {
  text-align: center;
}

.loginHeader {
  color: #707070;
  font-size: 24px;
  text-align: center;
  margin-bottom: 16px;
  font-weight: 300;
}

label {
  font-size: 16px;
}

#login-options {
  font-size: 16px;
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  padding-top: 10px;
  font-size: 11px;
  border-top: 1px solid #d2dde3;
}

.list-inline {
  li a, li p {
    font-weight: 600;
  }
}

.help-block {
  font-size: 11px;
  margin-bottom: 0;
}

.text-danger {
  color: red;
  font-size: 14px;
  margin-top: 3px;
}

.o-messages {
  width: 60%;
  margin: auto;
}

.sign-up-link-wrapper {
  margin-top: 20px;
  text-align: center;
}

@media screen and (max-width: @screen-sm-max) {
  .center-form {
    padding-top: 30px;
  }

  footer {
    height: 35px;
    padding-top: 0px;
  }

  .list-inline {
    margin-bottom: 0;
    p {
      margin-bottom: 0;
    }
  }

  .o-messages {
    width: 80%;
  }
}


.form-group {
  position: relative;
}


.floating-label {
  position: absolute;
  left: 20px;
  top: 11px;
  font-size: 16px;
  transition: 250ms;
  z-index: 0;
   &.red {
     color: red;
   }
}

.floating-label-active {
  font-size: 14px;
  left: 12px;
  top: -10px;
  background-color: white;
  padding-right: 10px;
  padding-left: 10px;
  color: #4596E8;
  z-index: 1;
  &.green {
    color: #44d7b6;
  }
   &.red {
     color: red;
   }

}

.emailInput {
  &.green {
    border: 1px solid #44d7b6;
    &:focus {
      border: 2px solid #44d7b6;
    }
  }
}.passwordInput {
  &.green {
    border: 1px solid #44d7b6;
     &:focus {
      border: 2px solid #44d7b6;
    }
  }
}

 .emailInput {
  &.red {
    border: 1px solid red;
     &:focus {
      border: 2px solid red;
    }
  }
}.passwordInput {
  &.red {
    border: 1px solid red;
    &:focus {
      border: 2px solid red;
    }
  }
}

@keyframes onAutoFillStart {  from {/**/}  to {/**/}}
@keyframes onAutoFillCancel {  from {/**/}  to {/**/}}

 input:-webkit-autofill {
    // Expose a hook for JavaScript when autofill is shown
    // JavaScript can capture 'animationstart' events
    animation-name: onAutoFillStart;

    // Make the background color become yellow really slowly
    transition: background-color 50000s ease-in-out 0s;
}
input:not(:-webkit-autofill) {
    // Expose a hook for JS onAutoFillCancel
    // JavaScript can capture 'animationstart' events
    animation-name: onAutoFillCancel;
}

  /* Customize the label (the container) */
  .checkboxContainer {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 12px;
    margin-top: 2px;
    width: auto;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .checkboxLabel {
    color: #999999;
    font-size: 12px;
    font-weight: normal;
  }

  /* Hide the browser's default checkbox */
  .checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
//
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    border: 1px solid #dae1ea;
    border-radius: 2px;
    margin-top: 2px;
  }

  /* On mouse-over, add a grey background color */
  .checkboxContainer:hover input ~ .checkmark {
    // background-color: #ccc;
    /*opacity: 0.7;*/
  }

  /* When the checkbox is checked, add a blue background */
  .checkboxContainer input:checked ~ .checkmark {
    background-color: #4596e8;
    border-color: transparent;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .checkboxContainer input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkboxContainer .checkmark:after {
    left: 5.5px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }



